@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./animation";
@import "./global";
@import "./pulse";

@import "./components/ion-modal";
@import "./components/button";
@import "./components/form";
@import "./components/scrollbar";

.big-alert {
  --width: 650px;
  --max-width: 650px;
}
