@import "./fonts/fonts";
@import "./scss/variables";
@import "./scss/components/button";
@import "./scss/components/form";
@import "./scss/components/scrollbar";
@import "./scss/components/popover";

.cdk-virtual-scroll-content-wrapper {
  width: calc(100% - 8px); // 8px for scrollbar
}
