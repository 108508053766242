@font-face {
  font-family: "BreeSerif";
  src: url("./Bree_Serif/BreeSerif-Regular.ttf");
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans/OpenSans-Regular.ttf");
}

:root {
  font-family: OpenSans;
  --ion-font-family: OpenSans;

  h1,
  h2,
  h3,
  h4,
  h5,
  ion-title,
  .title-font {
    font-family: BreeSerif;
    --ion-font-family: BreeSerif;
  }
}
